<template>
  <el-row :gutter="15" class="pt-5">
    <el-col :md="12">
      <el-form-item
        prop="external"
        :label="$t('pages.voucher_systems.form.properties.external.label')"
      >
        <el-switch
          :model-value="modelValue.external"
          :active-text="
            $t('pages.voucher_systems.form.properties.external.placeholder')
          "
          @change="
            (v) => $emit('update:modelValue', { ...modelValue, external: v })
          "
        />
      </el-form-item>

      <el-form-item
        prop="external_system_type"
        :label="
          $t('pages.voucher_systems.form.properties.external_system_type.label')
        "
      >
        <el-select
          v-model="value.external_system_type"
          :placeholder="
            $t(
              'pages.voucher_systems.form.properties.external_system_type.placeholder'
            )
          "
          clearable
          @change="
            (v) =>
              $emit('update:modelValue', {
                ...modelValue,
                external_system_type: v
              })
          "
        >
          <el-option
            v-for="item in avaialbleExternalTypes"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-col>

    <el-col :md="12">
      <el-form-item
        prop="external_system_id"
        :label="
          $t('pages.voucher_systems.form.properties.external_system_id.label')
        "
      >
        <el-input
          :model-value="modelValue.external_system_id"
          :placeholder="
            $t(
              'pages.voucher_systems.form.properties.external_system_id.placeholder'
            )
          "
          clearable
          @input="
            (v) =>
              $emit('update:modelValue', {
                ...modelValue,
                external_system_id: v
              })
          "
        />
      </el-form-item>

      <el-form-item
        prop="external_voucher_system_id"
        :label="
          $t(
            'pages.voucher_systems.form.properties.external_voucher_system_id.label'
          )
        "
      >
        <el-input
          :model-value="modelValue.external_voucher_system_id"
          :placeholder="
            $t(
              'pages.voucher_systems.form.properties.external_voucher_system_id.placeholder'
            )
          "
          clearable
          @input="
            (v) =>
              $emit('update:modelValue', {
                ...modelValue,
                external_voucher_system_id: v
              })
          "
        />
      </el-form-item>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      avaialbleExternalTypes: [
        {
          value: 'tillhub-voucher-api',
          label: this.$t(
            'pages.voucher_systems.form.properties.external_system_type.tillhub_voucher_api.label'
          )
        },
        {
          value: 'fleurop',
          label: this.$t(
            'pages.voucher_systems.form.properties.external_system_type.fleurop.label'
          )
        },
        {
          value: 'zalando',
          label: this.$t(
            'pages.voucher_systems.form.properties.external_system_type.zalando.label'
          )
        }
      ]
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>
