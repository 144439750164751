<template>
  <nav class="item-list-nav">
    <el-button
      class="button-mini"
      :disabled="disabled"
      @click="$emit('new', $event)"
    >
      <el-icon><Plus /></el-icon>
    </el-button>
  </nav>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="css" scoped>
.item-list-nav,
.item-list-nav > * {
  width: 100%;
}
</style>
