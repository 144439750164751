<template>
  <el-form
    ref="form"
    :model="modelValue"
    :rules="rules"
    class="form"
    label-position="top"
    label-width="120px"
  >
    <el-row :gutter="15" class="pt-5">
      <el-col :md="12">
        <el-form-item
          prop="name"
          :label="
            $t('pages.voucher_systems.form.properties.actions.name.label')
          "
        >
          <el-input
            v-model="value.name"
            :placeholder="
              $t(
                'pages.voucher_systems.form.properties.actions.name.placeholder'
              )
            "
            @input="handleChange('name', $event)"
          />
        </el-form-item>

        <el-form-item
          v-if="modelValue.type === 'create'"
          :label="
            $t('pages.voucher_systems.form.properties.actions.templates.label')
          "
          prop="templates"
        >
          <el-select
            v-model="value.templates"
            multiple
            :placeholder="
              $t(
                'pages.voucher_systems.form.properties.actions.templates.placeholder'
              )
            "
            clearable
            @change="handleChange('templates', $event)"
          >
            <el-option
              v-for="item in voucherSystem.templates || []"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          prop="product"
          :label="$t('pages.vouchers.edit.form.product.label')"
        >
          <remote-search-select
            class="branches-select"
            popper-append-to-body
            resource="products"
            :computed-fields="['custom_id', 'name']"
            :model-value="modelValue.product"
            :modify-query="modifyProductSearchQuery"
            @update:modelValue="handleChange('product', $event)"
          />
        </el-form-item>
      </el-col>
      <el-col :md="12">
        <el-form-item
          :label="
            $t('pages.voucher_systems.form.properties.actions.type.label')
          "
          prop="type"
        >
          <el-select
            v-model="value.type"
            :placeholder="
              $t(
                'pages.voucher_systems.form.properties.actions.type.placeholder'
              )
            "
            @change="handleChange('type', $event)"
          >
            <el-option
              v-for="item in availableTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item
          :label="
            $t('pages.voucher_systems.form.properties.actions.lookup.label')
          "
          prop="lookup"
        >
          <el-select
            v-model="value.lookup"
            :placeholder="
              $t(
                'pages.voucher_systems.form.properties.actions.lookup.placeholder'
              )
            "
            clearable
            @change="handleChange('lookup', $event)"
          >
            <el-option
              v-for="item in availableLookups"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import RemoteSearchSelect from '@/components/select/remote-search'

export default {
  components: {
    RemoteSearchSelect
  },
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: () => {}
    },
    voucherSystem: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    isNew: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      localValue: null,
      availableTypes: [
        {
          value: 'increment',
          label: this.$t(
            'pages.voucher_systems.form.properties.actions.type.increment.label'
          )
        },
        {
          value: 'decrement',
          label: this.$t(
            'pages.voucher_systems.form.properties.actions.type.decrement.label'
          )
        },
        {
          value: 'create',
          label: this.$t(
            'pages.voucher_systems.form.properties.actions.type.create.label'
          )
        }
      ],
      availableLookups: [
        {
          value: 'code',
          label: this.$t(
            'pages.voucher_systems.form.properties.actions.lookup.code.label'
          )
        }
      ],
      rules: {
        name: [
          {
            required: true,
            message: this.$t(
              'pages.vouchers.edit.form.name.validation.errors.required'
            ),
            trigger: 'blur'
          }
        ],
        type: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          }
        ],
        product: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          }
        ]
      }
    }
  },
  computed: {
    currency() {
      if (this.form && this.form.currency) return this.form.currency
      return null
    },
    active() {
      const doesExist =
        this.modelValue.attributes &&
        Object.prototype.hasOwnProperty.call(
          this.modelValue.attributes,
          'active'
        )
      return doesExist ? this.modelValue.attributes.active : true
    },
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  watch: {
    modelValue: {
      deep: true,
      handler: function (val) {
        this.localValue = this.$deepClone(val) // use deepClone to ensure reactivity in parent
      }
    }
  },
  created() {
    this.localValue = this.$deepClone(this.modelValue) // use deepClone to ensure reactivity in parent
  },
  methods: {
    emitChange() {
      this.$emit('form-change', this.localValue, this.index)
    },
    modifyProductSearchQuery(q) {
      return {
        limit: 50,
        deleted: false,
        q
      }
    },
    handleChange(field, val) {
      this.localValue[field] = val
      this.emitChange()
    }
  }
}
</script>

<style scoped>
.flags-group :deep(.el-select .el-tag) {
  color: #409eff;
  background-color: rgba(64, 158, 255, 0.1);
  border: 1px solid rgba(64, 158, 255, 0.2);
}

.form > * {
  width: 100%;
}
</style>
