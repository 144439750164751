<template>
  <el-form
    ref="form"
    :model="modelValue"
    :rules="rules"
    class="form"
    label-position="top"
    label-width="120px"
  >
    <el-row :gutter="15" class="pt-5">
      <el-col :md="12">
        <el-form-item prop="name" :label="$t('common.titles.name')">
          <el-input
            v-model="value.name"
            :placeholder="$t('common.titles.name')"
            @input="handleChange('name', $event)"
          />
        </el-form-item>

        <el-form-item
          prop="display_format"
          :label="
            $t('pages.voucher_systems.form.prefixes.display_format.label')
          "
        >
          <el-input
            v-model="value.display_format"
            :placeholder="
              $t(
                'pages.voucher_systems.form.prefixes.display_format.placeholder'
              )
            "
            @input="setFormat"
          />
        </el-form-item>
      </el-col>

      <el-col :md="12">
        <el-form-item
          prop="prefix"
          :label="$t('pages.voucher_systems.form.prefixes.prefix.label')"
        >
          <el-input
            v-model="value.prefix"
            :placeholder="
              $t('pages.voucher_systems.form.prefixes.prefix.placeholder')
            "
            @input="handleChange('prefix', $event)"
          />
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
/*
Notes regarding some choices in this component:

Why create a local copy of the prop "value": this component receives the form value from the parent but it cannot mutate this value directly. Consequently we will create a local copy of the value, which can be manipulated, and use event emitters in order to propagate the change up to the parent.

Why use deepClone(): all references to the original "value" prop need to be cut, otherwise the parent will not recognize changes and no update of the child will be triggered.
*/
export default {
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: () => {}
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      localValue: null,
      availableFormatTypes: ['alphanumeric', 'numeric', 'alphabetic'],
      rules: {
        name: [
          {
            required: true,
            message: this.$t(
              'pages.vouchers.edit.form.name.validation.errors.required'
            ),
            trigger: 'blur'
          },
          {
            max: 64,
            message: this.$t('common.forms.rules.max_length', { length: 64 }),
            trigger: 'blur'
          }
        ],
        prefix: [
          {
            required: true,
            message: this.$t(
              'pages.vouchers.edit.form.prefix.validation.errors.required'
            ),
            trigger: 'blur'
          },
          {
            max: 16,
            message: this.$t('common.forms.rules.max_length', { length: 16 }),
            trigger: 'blur'
          }
        ],
        display_format: [
          {
            max: 64,
            message: this.$t('common.forms.rules.max_length', { length: 64 }),
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  watch: {
    modelValue: {
      deep: true,
      handler: function () {
        this.cloneValue()
      }
    }
  },
  created() {
    this.cloneValue()
  },
  methods: {
    emitChange() {
      this.$emit('prefix-form-change', this.localValue, this.index)
    },
    cloneValue() {
      this.localValue = this.$deepClone(this.modelValue) // use deepClone to ensure reactivity in parent
    },
    setFormat(val) {
      // transform "format" string into "XXX"
      const re = /[^-\s]/g
      const newValue = val.replace(re, 'X')

      this.localValue.display_format = newValue
      this.emitChange()
    },
    handleChange(field, val) {
      this.localValue[field] = val
      this.emitChange()
    }
  }
}
</script>
