<template>
  <div class="form-wrapper">
    <el-row class="edit-form-wrapper flex justify-center">
      <el-col
        :xs="22"
        :sm="18"
        :md="18"
        :lg="16"
        :xl="10"
        class="form-container"
      >
        <el-form
          ref="form"
          v-loading="loading"
          class="form"
          :model="form"
          :rules="rules"
          label-position="top"
        >
          <th-tabs-form-wrapper
            v-model="activeTab"
            class="tab"
            :tabs="formTabs"
          >
            <template #system>
              <div class="mx-4 pt-5">
                <el-row :gutter="15">
                  <el-col :md="12">
                    <el-form-item
                      prop="name"
                      :label="
                        $t('pages.voucher_systems.form.attributes.name.label')
                      "
                    >
                      <el-input
                        v-model="form.name"
                        :placeholder="
                          $t(
                            'pages.voucher_systems.form.placeholder.name.placeholder'
                          )
                        "
                      />
                    </el-form-item>

                    <el-form-item
                      prop="active"
                      :label="
                        $t('pages.voucher_systems.form.attributes.active.label')
                      "
                    >
                      <el-switch
                        v-model="form.active"
                        :active-text="
                          $t(
                            'pages.voucher_systems.form.attributes.active.label'
                          )
                        "
                      >
                        <el-input v-model="form.active" />
                      </el-switch>
                    </el-form-item>

                    <el-form-item
                      prop="branches"
                      :label="
                        $t(
                          'pages.voucher_systems.form.attributes.branches.label'
                        )
                      "
                    >
                      <available-in
                        id="locations"
                        v-model="form.branches"
                        :resources="resources"
                        :show-items-limit="2"
                      />
                    </el-form-item>
                  </el-col>

                  <el-col :md="12">
                    <el-form-item
                      prop="description"
                      :label="
                        $t(
                          'pages.voucher_systems.form.attributes.description.label'
                        )
                      "
                    >
                      <el-input
                        v-model="form.description"
                        type="textarea"
                        :placeholder="
                          $t(
                            'pages.voucher_systems.form.placeholder.description.placeholder'
                          )
                        "
                      />
                    </el-form-item>

                    <el-form-item
                      prop="increments"
                      :label="
                        $t(
                          'pages.voucher_systems.form.attributes.increments.label'
                        )
                      "
                    >
                      <increments v-model="form.increments" />
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </template>

            <template #actions>
              <div class="mx-4">
                <actions-tab
                  ref="actions"
                  v-model="form.actions"
                  :voucher-system="form"
                  :is-new="isNew"
                  @form-change="updateActions"
                />
              </div>
            </template>
            <template #templates>
              <div class="mx-4">
                <templates-tab
                  ref="templates"
                  v-model="form.templates"
                  :is-new="isNew"
                  :current-default-currency="currentDefaultCurrency"
                  @templates-change="updateTemplates"
                />
              </div>
            </template>
            <template #prefixes>
              <div class="mx-4">
                <prefixes-tab
                  ref="templates"
                  v-model="form.scan_prefixes"
                  @prefixes-change="updatePrefixes"
                />
              </div>
            </template>
            <template #external>
              <div class="mx-4">
                <external-tab
                  ref="external"
                  v-model="form"
                  @update:modelValue="(v) => (form = { ...form, ...v })"
                />
              </div>
            </template>
          </th-tabs-form-wrapper>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import pick from 'just-pick'
import { isEmptyObject, isEmptyArray } from '@/utils/objects'
import { isEmptyString } from '@/utils/strings'
import AvailableIn from '@/components/available-in-legacy'
import Increments from './increments'
import TemplatesTab from './templates/tab'
import ActionsTab from './actions/tab'
import ExternalTab from './external/tab'
import PrefixesTab from './prefixes/tab'
import { useMessagesStore } from '@/store/messages'

function generateDefault() {
  return {
    name: null,
    description: null,
    branches: null,
    actions: null,
    active: true,
    increments: null,
    external: false,
    external_system_type: null,
    external_system_id: null,
    external_voucher_system_id: null,
    templates: [],
    scan_prefixes: []
  }
}

export default {
  name: 'VoucherSystemsForm',
  components: {
    Increments,
    TemplatesTab,
    ActionsTab,
    ExternalTab,
    PrefixesTab,
    AvailableIn
  },
  props: {
    isNew: {
      type: Boolean,
      required: true
    },
    currentDefaultCurrency: {
      type: String,
      required: false,
      default: undefined
    }
  },
  data() {
    return {
      activeTab: 'system',
      loading: false,
      form: generateDefault(),
      payload: {},
      rules: {
        name: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required')
          },
          {
            max: 32,
            message: this.$t('common.forms.rules.max_length', { length: 32 })
          }
        ],
        description: [
          {
            min: 1,
            max: 128,
            message: this.$t('common.forms.rules.max_length', { length: 128 })
          }
        ]
      },
      formTabs: [
        {
          name: 'system',
          label: this.$t('pages.voucher_systems.form.tabs.system.label')
        },
        {
          name: 'actions',
          label: this.$t('pages.voucher_systems.form.tabs.actions.label')
        },
        {
          name: 'templates',
          label: this.$t('pages.voucher_systems.form.tabs.templates.label')
        },
        {
          name: 'prefixes',
          label: this.$t('pages.voucher_systems.form.tabs.prefixes.label')
        },
        {
          name: 'external',
          label: this.$t('pages.voucher_systems.form.tabs.external.label')
        }
      ],
      resources: {}
    }
  },
  computed: {
    navigationAfterCreation() {
      return this.$store.getters['Config/getNavigationAfterCreation']
    }
  },
  created() {
    this.$emitter.on('save-requested', this.submitForm)
  },
  mounted() {
    this.fetchResources()
    if (!this.isNew) this.fetch(this.$route.params.id)
  },
  methods: {
    async fetch(id) {
      const errorMessage = this.$t(
        'pages.voucher_systems.edit.form.errors.fetch.code_XXX.content'
      )

      try {
        this.loading = true
        const { data = {} } = await th.voucherSystems().get(id)

        this.$emit('is-abocard', data.type === 'abocard')

        if (data.id) {
          this.handleItem(data)
        }
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    },
    submitForm() {
      const warningMessage = this.$t(
        'common.forms.rules.field_warnings.invalid_inputs.required'
      )

      this.validate('form', (valid) => {
        if (!valid) {
          return this.$message({
            type: 'warning',
            message: warningMessage
          })
        }

        this.$refs.templates.validate((templatesError) => {
          if (templatesError) {
            return this.$message({
              type: 'warning',
              message: warningMessage
            })
          }

          this.$refs.actions.validate((actionsError) => {
            if (actionsError) {
              return this.$message({
                type: 'warning',
                message: warningMessage
              })
            }

            this.loading = true
            const payload = this.makeHandleableBody(this.form)

            if (this.isNew) return this.create(payload)
            this.alter(this.payload.id, payload)
          })
        })
      })
    },
    validate(formName = 'form', cb) {
      this.$refs[formName].validate(cb)
    },
    async create(payload) {
      const errorMessage = this.$t('common.error.action.create.single', {
        resource: this.$t('common.resource.voucher_system.singular')
      })

      try {
        const { data = {}, errors = [] } = await th
          .voucherSystems()
          .create(payload)

        this.loading = false
        if (data.id) {
          this.$message({
            type: 'success',
            message: this.$t('common.success.action.create.single', {
              resource: this.$t('common.resource.voucher_system.singular')
            })
          })

          if (this.navigationAfterCreation === 'edit') {
            this.$router.push({
              name: 'voucher-systems-edit',
              params: { id: data.id }
            })
          } else {
            this.$router.push({ name: 'voucher-systems-list' })
          }
        }

        if (errors.length) {
          this.$log.debug(
            'voucher_systems-create: errors on success',
            JSON.stringify(errors, null, 2)
          )
          errors.forEach((errorObj) => {
            useMessagesStore().setLocalMessage({
              id: errorObj.id,
              label: errorObj.label,
              operation: 'local_message',
              payload: errorObj.errorDetails
            })
          })
        }
      } catch (err) {
        this.loading = false
        this.$logException(err, {
          message: errorMessage
        })
      }
    },
    async alter(id, payload) {
      const errorMessage = this.$t('common.error.action.update.single', {
        resource: this.$t('common.resource.voucher_system.singular')
      })
      try {
        const { data = {} } = await th.voucherSystems().put(id, payload)

        if (data.id) {
          this.$message({
            type: 'success',
            message: this.$t('common.success.action.update.single', {
              resource: this.$t('common.resource.voucher_system.singular')
            })
          })

          this.handleItem(data)
        }
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    },
    async handleDelete() {
      const confirm = await this.$askToDelete(
        this.payload.name || this.payload.id
      )
      if (confirm) this.deleteResource()
    },
    async deleteResource() {
      const errorMessage = this.$t('common.error.action.delete.single', {
        resource: this.$t('common.resource.voucher_system.singular')
      })

      try {
        await th.voucherSystems().delete(this.payload.id)

        this.resetForm()
        this.$router.push({ name: 'voucher-systems-list' })
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    },
    makeHandleableBody(form) {
      // replace empty objects, emtpy arrays and empty strings with null
      return Object.keys(form).reduce((result, key) => {
        const isEmpty =
          (isEmptyObject(form[key]) ||
            isEmptyArray(form[key]) ||
            isEmptyString(form[key])) &&
          key !== 'branches'
        result[key] = isEmpty ? null : form[key]
        return result
      }, {})
    },
    handleItem(item) {
      this.payload = item

      const cleanedPayload = pick(item, Object.keys(this.form))

      this.form = {
        ...this.form,
        ...cleanedPayload
      }
    },
    resetForm() {
      if (this.isNew) {
        this.$refs.form.resetFields()
      } else {
        this.handleItem(this.payload)
      }
    },
    updateTemplates(val) {
      this.form.templates = val
    },
    updatePrefixes(val) {
      this.form.scan_prefixes = val
    },
    updateActions(val) {
      this.form.actions = val
    },
    async fetchResources() {
      try {
        const {
          branchesV1 = [],
          branchGroups = []
        } = await this.$resourceFetch('branchesV1', 'branchGroups')
        this.resources = { branches: branchesV1, branchGroups }
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t(
            'pages.settings.users.edit.form.errors.fetch.resources.code_XXX.content'
          )
        })
      }
    }
  }
}
</script>

<style scoped>
.form-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.edit-form-wrapper {
  height: 100%;
  overflow: auto;
}

.form {
  height: 100%;
}

.form-container {
  height: 100%;
  padding-bottom: 2rem;
}

.actions {
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
  align-content: center;
  align-items: center;
}

.form-header {
  font-weight: bold;
  margin-top: 20px;
}

.el-input__icon {
  font-size: 18px;
  position: absolute;
  right: 15px;
}

.branches-select {
  width: 100%;
}

.tab {
  width: 100% !important;
}

.empty::before {
  content: '\200B';
}
</style>
