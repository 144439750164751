<template>
  <div>
    <div v-if="!modelValue || !modelValue.length" class="empty-space-message">
      {{ $t('pages.voucher_systems.form.templates.no_templates_available') }}
    </div>

    <div v-else>
      <div v-for="(template, index) in modelValue" :key="index">
        <template-form
          ref="templateForm"
          :model-value="modelValue[index]"
          :index="index"
          :is-new="isNew"
          :current-default-currency="currentDefaultCurrency"
          @template-form-change="updateTemplateForm"
        />

        <div class="delete-button-box">
          <el-button
            v-permissions="{ scopes: ['loyalty:voucher_systems:delete'] }"
            class="delete-button button-mini el-button--text-icon"
            icon="Delete"
            @click="deleteTemplate(index)"
          />
        </div>

        <hr v-if="index + 1 !== modelValue.length" class="th-divider" />
      </div>
    </div>

    <el-button class="add-button" size="small" @click="addNewTemplate">
      <el-icon><Plus /></el-icon>
    </el-button>
  </div>
</template>

<script>
import safeGet from 'just-safe-get'
import * as uuid from 'uuid'
import pAll from 'p-all'
import TemplateForm from './form'

/*
Notes regarding some choices in this component:

Why create an el-form for each template: in order to be able to run validations on each template object in the array. Vue Element form validation cannot handle deep validation of objects within arrays otherwise. Also, it is necessary to pass in a key with dot notation as a "prop" in the el-form-item in order to validate. el-form will throw warnings "Error: please transfer a valid prop path to form item" but no other solution was found to get deep validation of nested values working.

Why create a local copy of the prop "value": this component receives the form value from the parent but it cannot mutate this value directly. Consequently we will create a local copy of the value, which can be manipulated, and use event emitters in order to propagate the change up to the parent.

Why use deepClone(): all references to the original "value" prop need to be cut, otherwise the parent will not recognize changes and no update of the child will be triggered.
*/
function genInitial(currency) {
  return {
    id: uuid.v4(),
    name: null,
    code_generation: 'request',
    attributes: {
      format: null,
      format_type: null,
      active: true,
      regions: [],
      currency,
      type: 'amount',
      expires_at: null,
      redemption_valid_from: null,
      charge_valid_from: null,
      comment: null,
      amount: null,
      increment_amount_minimum: null,
      constraints: {},
      product: null,
      discount_rate: null,
      amount_max: null
    }
  }
}

export default {
  components: {
    TemplateForm
  },
  props: {
    modelValue: {
      type: Array,
      required: false,
      default: () => []
    },
    isNew: {
      type: Boolean,
      required: true
    },
    currentDefaultCurrency: {
      type: String,
      required: false,
      default: undefined
    }
  },
  data() {
    return {
      localValue: null
    }
  },
  watch: {
    modelValue: {
      deep: true,
      handler: function (value) {
        if (!value) {
          this.localValue = []
        } else {
          this.localValue = this.$deepClone(this.modelValue) // use deepClone to ensure reactivity in parent
        }
      }
    }
  },
  created() {
    if (!this.modelValue) {
      this.localValue = []
    } else {
      this.localValue = this.$deepClone(this.modelValue) // use deepClone to ensure reactivity in parent
    }
  },
  methods: {
    updateTemplateForm(val, index) {
      this.localValue[index] = val
      this.emitChange()
    },
    async validate(cb) {
      const forms = this.$refs.templateForm || []

      // iterate through all template forms and call their validate() function
      const validators = forms.map((form) =>
        safeGet(form, '$refs.form.validate')
      )

      try {
        await pAll(validators, { concurrency: 5 })
      } catch (err) {
        return cb(new Error('template forms validation failed'))
      }

      cb(null)
    },
    emitChange() {
      this.$emit('templates-change', this.localValue)
    },
    addNewTemplate() {
      this.localValue.push(genInitial(this.currentDefaultCurrency)) // in created() we make sure it is always an array
      this.emitChange()
    },
    async deleteTemplate(index) {
      const confirm = await this.$askToDelete(
        this.localValue[index].name ? ` "${this.localValue[index].name}"` : ''
      )
      if (confirm) {
        this.localValue.splice(index, 1)
        this.emitChange()
      }
    }
  }
}
</script>

<style scoped>
.th-divider {
  margin: 1.6rem 0;
  border-top-color: unset;
}

.add-button {
  width: 100%;
  margin: 30px 0;
}

.delete-button-box {
  display: flex;
  justify-content: flex-end;
}

.delete-button {
  margin: 1em;
}

.el-icon-delete {
  color: red;
  cursor: pointer;
  margin: 10px;
}

.empty-space-message {
  margin: 30px 0;
  color: var(--label-text-color);
}
</style>
