<template>
  <div>
    <property-form
      ref="propertyForm"
      :model-value="modelValue"
      @update:modelValue="
        (v) => $emit('update:modelValue', { ...modelValue, ...v })
      "
    />
  </div>
</template>

<script>
import PropertyForm from './form'
export default {
  components: {
    PropertyForm
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="css" scoped></style>
