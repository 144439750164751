<template>
  <el-form
    ref="form"
    :model="modelValue"
    :rules="rules"
    class="form"
    label-position="top"
    label-width="120px"
  >
    <el-row :gutter="15" class="pt-5">
      <el-col :md="12">
        <el-form-item
          prop="name"
          :label="
            $t('pages.voucher_systems.form.templates.attributes.name.label')
          "
        >
          <el-input
            v-model="name"
            :placeholder="
              $t(
                'pages.voucher_systems.form.templates.attributes.name.placeholder'
              )
            "
          />
        </el-form-item>

        <el-form-item
          prop="attributes.format"
          :label="
            $t('pages.voucher_systems.form.templates.attributes.format.label')
          "
        >
          <el-input
            v-model="form.format"
            :placeholder="
              $t(
                'pages.voucher_systems.form.templates.attributes.format.placeholder'
              )
            "
            @input="(val) => safeSetAttribute('format', val)"
          />
        </el-form-item>

        <el-form-item
          prop="attributes.format_type"
          :label="
            $t(
              'pages.voucher_systems.form.templates.attributes.format_type.label'
            )
          "
        >
          <el-select
            v-model="form.format_type"
            :placeholder="
              $t(
                'pages.voucher_systems.form.templates.attributes.format_type.placeholder'
              )
            "
            @change="(val) => safeSetAttribute('format_type', val)"
          >
            <el-option
              v-for="item in availableFormatTypes"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('pages.vouchers.edit.form.attributes.label')">
          <el-select
            v-model="flags"
            multiple
            :placeholder="$t('pages.vouchers.edit.form.attributes.placeholder')"
            @change="handleFlagChange"
          >
            <el-option
              v-for="item in flagOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item
          :label="$t('pages.vouchers.edit.form.type.label')"
          prop="type"
        >
          <el-radio-group
            v-model="form.type"
            :aria-label="$t('pages.vouchers.edit.form.type.label')"
            @change="(val) => safeSetAttribute('type', val)"
          >
            <el-radio-button label="discount">
              <div class="w-16">
                {{ $t('pages.vouchers.edit.form.type.values.discount') }}
              </div>
            </el-radio-button>
            <el-radio-button label="amount">
              <div class="w-16">
                {{ $t('pages.vouchers.edit.form.type.values.amount') }}
              </div>
            </el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          v-if="isAmount && currencies.length > 1"
          :label="$t('pages.vouchers.edit.form.currency.label')"
          prop="currency"
        >
          <th-currency-select
            :allowed-currencies="currencies"
            :model-value="form.currency"
            clearable
            @update:modelValue="(val) => safeSetAttribute('currency', val)"
            @clear="handleCurrencyClear"
          />
        </el-form-item>
        <el-form-item
          v-if="isAmount"
          :label="$t('common.forms.labels.amount')"
          prop="amount"
        >
          <th-currency-input
            prominent
            :currency="form.currency"
            :model-value="form.amount"
            @update:modelValue="(val) => safeSetAttribute('amount', val)"
          />
        </el-form-item>
        <el-form-item
          v-if="isAmount"
          :label="$t('pages.vouchers.edit.form.amount_max.label')"
          prop="amount_max"
        >
          <th-currency-input
            :currency="form.currency"
            :model-value="form.amount_max"
            @update:modelValue="(val) => safeSetAttribute('amount_max', val)"
          />
        </el-form-item>
        <el-form-item
          v-if="isAmount"
          :label="$t('pages.vouchers.edit.form.increment_amount_minimum.label')"
          prop="increment_amount_minimum"
        >
          <th-currency-input
            :currency="form.currency"
            :model-value="form.increment_amount_minimum"
            @update:modelValue="
              (val) => safeSetAttribute('increment_amount_minimum', val)
            "
          />
        </el-form-item>

        <el-form-item
          v-if="isDiscount"
          prop="discount_rate"
          :label="$t('pages.vouchers.edit.form.rate.label')"
        >
          <th-number-input
            id="discount_rate"
            :model-value="form.discount_rate"
            clearable
            clearable-is-left
            percent
            class="w-56"
            :upper-limit="1.0"
            :precision="2"
            :locale="$i18n.locale"
            @update:modelValue="(val) => safeSetAttribute('discount_rate', val)"
          />
        </el-form-item>

        <el-form-item prop="active">
          <el-checkbox
            v-model="active"
            @change="(val) => safeSetAttribute('active', val)"
          >
            {{ $t('pages.vouchers.edit.form.active.label') }}
          </el-checkbox>
        </el-form-item>

        <el-form-item
          :label="$t('pages.vouchers.edit.form.region.label')"
          prop="regions"
        >
          <el-select
            v-model="form.regions"
            clearable
            multiple
            :placeholder="$t('pages.vouchers.edit.form.region.placeholder')"
            @change="(val) => safeSetAttribute('regions', val)"
          >
            <el-option
              v-for="item in availableCountries"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :md="12">
        <el-form-item
          prop="code_generation"
          :label="$t('pages.vouchers.edit.form.code_generation.label')"
        >
          <el-select v-model="codeGeneration">
            <el-option
              v-for="{ key, label } in codeGenerationTypes"
              :key="key"
              :value="key"
              :label="label"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          prop="product"
          :label="$t('pages.vouchers.edit.form.product.label')"
        >
          <remote-search-select
            class="branches-select"
            fetch-handler="getAll"
            :computed-fields="['custom_id', 'name']"
            :modify-query="modifyProductSearchQuery"
            :model-value="form.product || null"
            resource="products"
            @update:modelValue="(val) => safeSetAttribute('product', val)"
          />
        </el-form-item>
        <el-form-item
          prop="expires_at"
          :label="$t('pages.vouchers.edit.form.expires_at.label')"
        >
          <date-picker-input
            :placeholder="
              $t('pages.vouchers.edit.form.expires_at.day.placeholder')
            "
            :model-value="form.expires_at"
            @update:modelValue="(val) => safeSetAttribute('expires_at', val)"
          />
        </el-form-item>
        <el-form-item
          prop="redemption_valid_from"
          :label="$t('pages.vouchers.edit.form.redemption_valid_from.label')"
        >
          <date-picker-input
            :type="dateTypes.DATE_TIME"
            :date-format="$date.getDateTimeFormat()"
            :placeholder="
              $t('pages.vouchers.edit.form.redemption_valid_from.placeholder')
            "
            :model-value="form.redemption_valid_from || '00:00:00'"
            @update:modelValue="
              (val) => safeSetAttribute('redemption_valid_from', val)
            "
          />
        </el-form-item>
        <el-form-item
          prop="charge_valid_from"
          :label="$t('pages.vouchers.edit.form.charge_valid_from.label')"
        >
          <date-picker-input
            :type="dateTypes.DATE_TIME"
            :date-format="$date.getDateTimeFormat()"
            :placeholder="
              $t('pages.vouchers.edit.form.redemption_valid_from.placeholder')
            "
            :model-value="form.charge_valid_from || '00:00:00'"
            @update:modelValue="
              (val) => safeSetAttribute('charge_valid_from', val)
            "
          />
        </el-form-item>
        <el-form-item
          :label="$t('pages.vouchers.edit.form.comment.label')"
          prop="comment"
        >
          <el-input
            v-model="form.comment"
            type="textarea"
            @input="(val) => safeSetAttribute('comment', val)"
          />
        </el-form-item>

        <constraints
          :model-value="form.constraints"
          @constraints-updated="updateConstraints"
        />
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import safeGet from 'just-safe-get'
import safeSet from 'just-safe-set'
import { mapGetters } from 'vuex'
import RemoteSearchSelect from '@/components/remote-search-select'
import { availableCountries } from '../../../vouchers-manager/components/voucherConst'
import Constraints from '../../../vouchers-manager/components/constraints'
import DatePickerInput from '@/components/inputs/date-picker'
import { dateTypes } from '@/constants/date'

/*
Notes regarding some choices in this component:

Why create a local copy of the prop "value": this component receives the form value from the parent but it cannot mutate this value directly. Consequently we will create a local copy of the value, which can be manipulated, and use event emitters in order to propagate the change up to the parent.

Why use deepClone(): all references to the original "value" prop need to be cut, otherwise the parent will not recognize changes and no update of the child will be triggered.
*/

export default {
  components: {
    DatePickerInput,
    Constraints,
    RemoteSearchSelect
  },
  props: {
    modelValue: {
      type: Object,
      required: false,
      default: () => {}
    },
    index: {
      type: Number,
      required: true
    },
    isNew: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      dateTypes,
      localValue: null,
      availableCountries: availableCountries,
      availableFormatTypes: ['alphanumeric', 'numeric', 'alphabetic'],
      codeGenerationTypes: [
        {
          key: 'scan',
          label: this.$t('pages.vouchers.edit.form.code_generation.types.scan')
        },
        {
          key: 'request',
          label: this.$t(
            'pages.vouchers.edit.form.code_generation.types.request'
          )
        }
      ],
      flags: [],
      rules: {
        name: [
          {
            required: true,
            message: this.$t(
              'pages.vouchers.edit.form.name.validation.errors.required'
            ),
            trigger: 'blur'
          }
        ],
        attributes: {
          format: {
            required: true,
            message: this.$t(
              'pages.vouchers.edit.form.format.validation.errors.required'
            ),
            trigger: 'blur'
          },
          format_type: {
            required: true,
            message: this.$t(
              'pages.vouchers.edit.form.format.validation.errors.required'
            ),
            trigger: ['blur', 'change']
          }
        },
        code_generation: [
          {
            required: true,
            message: this.$t(
              'pages.vouchers.edit.form.name.validation.errors.required'
            ),
            trigger: 'change'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      defaultCurrency: 'Config/getCurrentDefaultCurrency',
      currencies: 'Config/getAvailableCurrencies'
    }),
    form() {
      return this.modelValue?.attributes ?? {}
    },
    popovers() {
      return {
        format: this.$t('pages.vouchers.edit.form.format.tooltip')
      }
    },
    flagOptions() {
      return [
        {
          value: 'issuable',
          label: this.$t(
            'pages.vouchers.edit.properties.attributes.issuable.label'
          )
        },
        {
          value: 'reissuable',
          label: this.$t(
            'pages.vouchers.edit.properties.attributes.reissuable.label'
          )
        },
        {
          value: 'partial_redemption',
          label: this.$t(
            'pages.vouchers.edit.properties.attributes.partial_redemption.label'
          )
        },
        {
          value: 'limited_to_region',
          label: this.$t(
            'pages.vouchers.edit.properties.attributes.limited_to_region.label'
          )
        },
        {
          value: 'refundable',
          label: this.$t(
            'pages.vouchers.edit.properties.attributes.refundable.label'
          )
        },
        {
          value: 'exchange_for_cash',
          label: this.$t(
            'pages.vouchers.edit.properties.attributes.exchange_for_cash.label'
          )
        },
        {
          value: 'restriction_single_transaction',
          label: this.$t(
            'pages.vouchers.edit.properties.attributes.restriction_single_transaction.label'
          )
        },
        {
          value: 'is_campaign',
          label: this.$t(
            'pages.vouchers.edit.properties.attributes.is_campaign.label'
          )
        }
      ]
    },
    currency() {
      if (this.form && this.form.currency) return this.form.currency
      return null
    },
    active() {
      const doesExist =
        this.modelValue.attributes &&
        Object.prototype.hasOwnProperty.call(
          this.modelValue.attributes,
          'active'
        )
      return doesExist ? this.modelValue.attributes.active : true
    },
    codeGeneration: {
      get() {
        return this.modelValue.code_generation
      },
      set(newValue) {
        this.localValue.code_generation = newValue
        this.emitChange()
      }
    },
    name: {
      get() {
        return this.modelValue.name
      },
      set(newValue) {
        this.localValue.name = newValue
        this.emitChange()
      }
    },
    isDiscount() {
      return this.form.type === 'discount'
    },
    isAmount() {
      return this.form.type === 'amount'
    }
  },
  watch: {
    modelValue: {
      deep: true,
      handler: function (val) {
        this.localValue = this.$deepClone(val) // use deepClone to ensure reactivity in parent
      }
    }
  },
  created() {
    this.localValue = this.$deepClone(this.modelValue) // use deepClone to ensure reactivity in parent
    this.setCurrency()
    // parse initial values for multi-select "flags", which looks like ["mutable", "issuable"]
    if (!this.isNew) {
      this.flags = this.flagOptions
        .filter(
          (option) => !!safeGet(this.localValue, `attributes.${option.value}`)
        )
        .map((option) => option.value)
    }
  },
  mounted() {
    if (this.isNew && this.defaultCurrency) {
      this.safeSetAttribute('currency', this.defaultCurrency)
    }
  },
  methods: {
    handleFlagChange() {
      const flagsMap = {}
      this.flagOptions.forEach((option) => {
        flagsMap[option.value] = this.flags.includes(option.value)
      })

      this.localValue = {
        ...this.localValue,
        attributes: {
          ...this.localValue.attributes,
          ...flagsMap
        }
      }

      this.emitChange()
    },
    emitChange() {
      this.$emit('template-form-change', this.localValue, this.index)
    },
    safeSetAttribute(attributeName, val) {
      let newValue = val

      // transform "format" string into "XXX"
      if (attributeName === 'format') {
        const re = /[^-\s]/g
        newValue = val.replace(re, 'X')
      }

      // nullify the empty strings that el-select components return when cleared
      if (val === '') newValue = null

      safeSet(this.localValue, `attributes.${attributeName}`, newValue)
      this.emitChange()
    },
    handleCurrencyClear() {
      this.safeSetAttribute('amount', null)
      this.safeSetAttribute('increment_amount_minimum', null)
    },
    updateConstraints(value) {
      this.safeSetAttribute('constraints', value)
    },
    modifyProductSearchQuery(query) {
      return { deleted: false, q: query }
    },
    setCurrency() {
      //If has only one currency, the currency will be automatically set as the default
      if (this.currencies.length === 1) {
        this.safeSetAttribute('currency', this.currencies[0])
      }
    }
  }
}
</script>
