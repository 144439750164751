<template>
  <div>
    <div v-if="!modelValue || !modelValue.length" class="empty-space-message">
      {{ $t('pages.voucher_systems.form.actions.no_actions_available') }}
    </div>

    <div v-else>
      <div v-for="(item, index) in modelValue" :key="item.id">
        <property-form
          ref="propertyForm"
          :model-value="item"
          :index="index"
          :voucher-system="voucherSystem"
          :is-new="isNew"
          @form-change="updatePropertyForm"
        />

        <div class="delete-button-box">
          <el-button
            v-permissions="{ scopes: ['loyalty:voucher_systems:delete'] }"
            class="delete-button button-mini el-button--text-icon"
            icon="Delete"
            @click="deleteResource(index)"
          />
        </div>

        <hr v-if="index + 1 !== modelValue.length" class="th-divider" />
      </div>
    </div>

    <el-button class="add-button" size="small" @click="addNewAction">
      <el-icon><Plus /></el-icon>
    </el-button>
  </div>
</template>

<script>
import safeGet from 'just-safe-get'
import * as uuid from 'uuid'
import pAll from 'p-all'
import PropertyForm from './form'

function genInitial() {
  return {
    id: uuid.v4(),
    name: null,
    type: null,
    lookup: 'code',
    templates: undefined,
    context: null
  }
}

export default {
  components: {
    PropertyForm
  },
  props: {
    modelValue: {
      type: Array,
      required: false,
      default: () => []
    },
    voucherSystem: {
      type: Object,
      required: true
    },
    isNew: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      localValue: null
    }
  },
  watch: {
    modelValue: {
      deep: true,
      handler: function (value) {
        if (!value) {
          this.localValue = []
        } else {
          this.localValue = this.$deepClone(this.modelValue) // use deepClone to ensure reactivity in parent
        }
      }
    }
  },
  created() {
    if (!this.modelValue) {
      this.localValue = []
    } else {
      this.localValue = this.$deepClone(this.modelValue) // use deepClone to ensure reactivity in parent
    }
  },
  methods: {
    updatePropertyForm(val, index) {
      this.localValue[index] = val
      this.emitChange()
    },
    async validate(cb) {
      const forms = this.$refs.propertyForm || []

      // iterate through all template forms and call their validate() function
      const validators = forms.map((form) =>
        safeGet(form, '$refs.form.validate')
      )

      try {
        await pAll(validators, { concurrency: 5 })
      } catch (err) {
        return cb(new Error('Form validation failed'))
      }

      cb(null)
    },
    emitChange() {
      this.$emit('form-change', this.localValue)
    },
    addNewAction() {
      this.localValue.push(genInitial())
      this.emitChange()
    },
    deleteResource(index) {
      this.localValue.splice(index, 1)
      this.emitChange()
    }
  }
}
</script>

<style scoped>
.th-divider {
  margin: 1.6rem 0;
  border-top-color: unset;
}

.add-button {
  width: 100%;
  margin: 30px 0;
}

.delete-button-box {
  display: flex;
  justify-content: flex-end;
}

.delete-button {
  margin: 1em;
}

.el-icon-delete {
  color: red;
  cursor: pointer;
  margin: 10px;
}

.empty-space-message {
  margin: 30px 0;
  color: var(--label-text-color);
}
</style>
