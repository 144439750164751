<template>
  <div class="voucher-systems-increments">
    <div class="increments-holder">
      <div v-for="(item, index) in modelValue" :key="index">
        <el-row
          type="flex"
          align="center"
          justify="center"
          class="mb-2"
          :gutter="4"
        >
          <el-col :span="6">
            <el-select
              v-model="item.type"
              @change="(v) => handleIncrementInput(index, item, 'type', v)"
            >
              <el-option
                v-for="(option, oIndex) in availableOptions"
                :key="oIndex"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </el-col>
          <el-col v-if="item.type === 'amount'" :span="8">
            <th-currency-select
              :model-value="item.currency"
              @update:modelValue="
                (v) => handleIncrementInput(index, item, 'currency', v)
              "
            />
          </el-col>
          <el-col v-if="item.type === 'amount'" :span="8">
            <th-currency-input
              :model-value="item.value"
              :currency="item.currency"
              @update:modelValue="
                (v) => handleIncrementInput(index, item, 'value', v)
              "
            />
          </el-col>

          <el-col :span="2" class="delete-holder">
            <el-button
              data-testid="delete-button"
              icon="Delete"
              class="el-button--text-icon"
              @click="removeItem(index)"
            />
          </el-col>
        </el-row>
      </div>
    </div>
    <add-bar @new="handleNew" />
  </div>
</template>

<script>
import AddBar from '@/components/tillhub/add-bar'

export default {
  components: {
    AddBar
  },
  props: {
    modelValue: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      defaultCurrency: this.$store.getters['Config/getCurrentDefaultCurrency'],
      availableOptions: [
        {
          label: this.$t(
            'pages.voucher_systems.form.attributes.increments.type.options.amount.label'
          ),
          value: 'amount'
        }
      ]
    }
  },
  methods: {
    handleNew() {
      let arr = this.modelValue
      if (!this.modelValue) {
        arr = []
      }

      arr.push({
        currency: this.defaultCurrency,
        type: 'amount',
        value: null
      })

      this.$emit('update:modelValue', arr)
    },
    handleIncrementInput(index, item, path, v) {
      const arr = [...this.modelValue].map((mapItem, itemIndex) => {
        const obj = { ...mapItem }
        if (itemIndex === index) {
          obj[path] = v
        }
        return obj
      })

      this.$emit('update:modelValue', arr)
    },
    removeItem(index) {
      const items = [...this.modelValue].filter(
        (item, itemIndex) => itemIndex !== index
      )
      this.$emit('update:modelValue', items)
    }
  }
}
</script>

<style lang="css" scoped>
.voucher-systems-increments {
  width: 100%;
}
.delete-holder {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
}
</style>
